var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { staticClass: "header", attrs: { color: "black", app: "" } },
    [
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        [
          _c("avatar-menu", {
            attrs: { account: _vm.account, initialUser: _vm.initialUser },
            on: { logout: _vm.logout }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }