var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pb-2" },
    [
      _c("v-card-title", [_c("h3", [_vm._v("Ben je zeker?")])]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { staticClass: "ma-3" }, [
                _c("p", { staticClass: "mb-3" }, [
                  _vm._v(
                    " Ben je zeker dat je dit permanent wilt verwijderen? "
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "mr-2 d-flex justify-center" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "secondary", outlined: "" },
              on: { click: _vm.cancelClicked }
            },
            [_vm._v("Anulleer")]
          ),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.deleteClicked } },
            [_vm._v("Permanent verwijderen")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }