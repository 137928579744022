var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.iconColor && _vm.iconType
        ? _c(
            "v-icon",
            {
              style: _vm.editable ? {} : { cursor: "default" },
              attrs: { color: _vm.iconColor },
              on: {
                click: function($event) {
                  _vm.showDialog = true
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s("mdi-" + _vm.iconType + "-circle-outline") + " "
              )
            ]
          )
        : _vm._e(),
      _vm.editable && _vm.showDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "350px" },
              model: {
                value: _vm.showDialog,
                callback: function($$v) {
                  _vm.showDialog = $$v
                },
                expression: "showDialog"
              }
            },
            [
              _vm.showDialog
                ? _c("payment-change-status", {
                    attrs: { status: _vm.pStatus, docuId: _vm.pFileId },
                    on: {
                      save: _vm.updateStatus,
                      downloadPayment: _vm.downloadPayment
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }