var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input--container d-flex justify-space-between" },
    [
      _c("v-select", {
        staticClass: "input--filter",
        attrs: {
          items: _vm.sortedFilterOptionsList,
          "item-text": "label",
          "return-object": "",
          "item-disabled": "disable"
        },
        model: {
          value: _vm.select,
          callback: function($$v) {
            _vm.select = $$v
          },
          expression: "select"
        }
      }),
      _vm.select
        ? _c("dynamic-filter-component", {
            attrs: {
              component: _vm.select.component,
              dataStoreAction: _vm.select.dataStoreAction || null,
              dataStorePath: _vm.select.dataStorePath || null,
              value: _vm.select.value,
              label: _vm.select.label,
              selectedItems: _vm.selectedChildItems,
              namespace: _vm.namespace
            },
            on: { selectedChanged: _vm.onSelectedChanged }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }