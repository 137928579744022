var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex align-center" },
      [
        _c(
          "v-dialog",
          {
            attrs: { width: "700" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _vm.showSearchBar
                      ? _c("filter-search-component", {
                          attrs: { label: _vm.searchLabel },
                          on: { search: _vm.onSearch }
                        })
                      : _c("v-spacer"),
                    _vm.showFilters
                      ? _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "white--text ml-4",
                                attrs: { "x-large": "", color: "blue-grey" }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              { attrs: { small: "", left: "", dark: "" } },
                              [_vm._v("mdi-filter-variant")]
                            ),
                            _vm._v(" Filters ")
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ]),
            model: {
              value: _vm.showDialog,
              callback: function($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog"
            }
          },
          [
            _vm.showDialog
              ? _c(
                  "v-card",
                  { staticClass: "pb-2", attrs: { id: "filterModal" } },
                  [
                    _c("v-card-title", [_c("h3", [_vm._v("Filters")])]),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "", wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              { staticClass: "ma-3" },
                              [
                                _c("p", { staticClass: "mb-6" }, [
                                  _vm._v(
                                    " Om een filter toe te voegen aan de selectie drukken we op de knop "
                                  ),
                                  _c("b", [_vm._v("Filter toevoegen")]),
                                  _vm._v(". Gebruik de knop "),
                                  _c("b", [_vm._v("Filter toepassen")]),
                                  _vm._v(" om de selectie te filteren. ")
                                ]),
                                _vm._l(_vm.dataFilters, function(filter) {
                                  return _c("div", { key: filter.key }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between"
                                      },
                                      [
                                        _c("filter-selector-component", {
                                          attrs: {
                                            namespace: _vm.namespace,
                                            filterOptions: _vm.filterItems,
                                            selectedOption:
                                              filter.selectedOption,
                                            selectedItems:
                                              filter.selectedValues || []
                                          },
                                          on: {
                                            selectedFilter: function($event) {
                                              return _vm.onFilterSelect(
                                                filter,
                                                $event
                                              )
                                            },
                                            filterSelections: function($event) {
                                              return _vm.onFilterSelectionChanged(
                                                filter,
                                                $event
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "delete--btn mt-3",
                                            attrs: {
                                              elevation: "0",
                                              large: "",
                                              color: "white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteFilter(filter)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { medium: "", dark: "" }
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-filter-remove-outline"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-space-between px-6" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "secondary", outlined: "" },
                            on: { click: _vm.addFilter }
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { small: "", dark: "", left: "" } },
                              [_vm._v("mdi-filter-plus")]
                            ),
                            _vm._v(" FILTER TOEVOEGEN ")
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "secondary", outlined: "" },
                            on: { click: _vm.submitFilters }
                          },
                          [_vm._v("TOEPASSEN")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "text-right" },
      _vm._l(_vm.usedFiltersChipList, function(chip) {
        return _c(
          "v-chip",
          {
            key: chip.index,
            staticClass: "ma-2",
            attrs: { small: "", close: "" },
            on: {
              "click:close": function($event) {
                return _vm.deleteFilter(chip.filter, true)
              }
            }
          },
          [_vm._v(" " + _vm._s(chip.parent) + ": " + _vm._s(chip.values) + " ")]
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }