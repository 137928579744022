var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v-toast-container toast-position-ne" },
    [
      _c(
        "transition-group",
        { attrs: { name: "toast", tag: "div" } },
        _vm._l(_vm.messages, function(message) {
          return _c(
            "div",
            { key: message.id, staticClass: "toast-wrapper" },
            [
              _c("toast", {
                attrs: {
                  message: message.message,
                  params: message.params,
                  type: message.type,
                  icon: message.icon,
                  actions: message.actions
                },
                on: {
                  close: function($event) {
                    return _vm.close(message.id)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }