var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_c("h3", [_vm._v("Verander status")])]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { staticClass: "pa-5", attrs: { md12: "" } }, [
                _c(
                  "div",
                  { staticClass: "radio-tile-group" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "white--text",
                        attrs: {
                          color: "green",
                          outlined: !(_vm.pStatus == _vm.PAYMENT_STATUS.PAID)
                        },
                        on: {
                          click: function($event) {
                            _vm.pStatus = _vm.PAYMENT_STATUS.PAID
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-checkbox-marked-circle-outline")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "white--text",
                        attrs: {
                          color: "red",
                          outlined: _vm.pStatus == _vm.PAYMENT_STATUS.PAID
                        },
                        on: {
                          click: function($event) {
                            _vm.pStatus = _vm.PAYMENT_STATUS.UN_PAID
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-close-circle-outline")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "v-btn",
            { attrs: { color: "" }, on: { click: _vm.downloadDocu } },
            [_c("v-icon", [_vm._v("mdi-download")]), _vm._v(" Download ")],
            1
          ),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.saveClicked } },
            [_vm._v("Controle Opslaan")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }