import { render, staticRenderFns } from "./confirm-delete.vue?vue&type=template&id=342f619f&scoped=true&"
import script from "./confirm-delete.vue?vue&type=script&lang=js&"
export * from "./confirm-delete.vue?vue&type=script&lang=js&"
import style0 from "./confirm-delete.vue?vue&type=style&index=0&id=342f619f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342f619f",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('342f619f')) {
      api.createRecord('342f619f', component.options)
    } else {
      api.reload('342f619f', component.options)
    }
    module.hot.accept("./confirm-delete.vue?vue&type=template&id=342f619f&scoped=true&", function () {
      api.rerender('342f619f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dialog/confirm-delete.vue"
export default component.exports