var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("h3", [_vm._v(_vm._s(_vm.$t("general.payment.upload-picture")))])
      ]),
      _c(
        "v-card-text",
        { staticClass: "text-center" },
        [
          _c("vue-avatar", {
            ref: "vueavatar",
            attrs: {
              width: _vm.avatarWidth,
              height: _vm.avatarHeight,
              scale: _vm.scale
            },
            on: {
              "vue-avatar-editor:image-ready": _vm.onImageReady,
              "select-file": function($event) {
                return _vm.passportUpload($event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "mr-2 upload--bottom" },
        [
          _c(
            "label",
            { staticClass: "ml-6" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("general.payment.zoom-picture")) +
                  " : " +
                  _vm._s(_vm.scale) +
                  "x "
              ),
              _c("br"),
              _c("v-slider", {
                attrs: {
                  "hide-details": "auto",
                  max: "3",
                  min: "0",
                  step: "0.02"
                },
                model: {
                  value: _vm.scale,
                  callback: function($$v) {
                    _vm.scale = $$v
                  },
                  expression: "scale"
                }
              })
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", disabled: _vm.uploadDisable },
              on: { click: _vm.saveClicked }
            },
            [_vm._v(_vm._s(_vm.$t("general.payment.upload-picture")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }